import React from "react";
import { Content } from "./Content";
import { pageHeadingStyles } from "./Headings";
import { alternativeStyles } from "./CallToAction";
import { graphql, useStaticQuery } from "gatsby";
import { PageWidth, Section } from "./Containers";
import { join } from "../styles/styles";

export function GetStartedSection({ color = "text-ink", bgColor= "bg-page" }) {
  return <PageWidth className={join(color, bgColor, "py-40")}>
    <Section className="text-center">
      <GetStarted />
    </Section>
  </PageWidth>
}

export function GetStarted() {
  const { content } = useStaticQuery(query)

  return (content && <Content content={content}
    richTextStyles={pageHeadingStyles}
    callToActionStyles={alternativeStyles}
  />) || null
}

const query = graphql`{
  content: contentfulSiteContent(key: {eq: "STATIC: get started"}) {
    ...Content
  }
}`