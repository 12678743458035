import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../layouts/Site"
import { slug } from "../gatsby/articles.create"
import { RichText } from "../components/RichText"
import { PageWidth, Section } from "../components/Containers"
import { PageHeading, pageHeadingStyles } from "../components/Headings"
import { join, sectionEndLine } from "../styles/styles"
import { Image } from "../components/Image"
import { Flow } from "../components/Flow";
import { GetStartedSection } from "../components/GetStarted";
import { Helmet } from "react-helmet";

export default function ServiceTemplate({ data }) {
  const { site, service, defaultMetadata } = data
  service.url = `${site.siteMetadata.url}${slug(service.key)}`

  return <Layout title={service.name} darkFooter>
    <Helmet>
      <meta name="description" content={service.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <PageWidth className={join(sectionEndLine, "pt-header md:pt-header-md")}>
      <Section cols="auto" className="pt-4">
        <PageHeading>{service.name}</PageHeading>
        <Image image={service.image} />
      </Section>
    </PageWidth>
    <PageWidth>
      <Section>
        <RichText body={service.body} styles={pageHeadingStyles} />
      </Section>
    </PageWidth>
    <PageWidth className="bg-primary text-page">
      <FlowSection flow={service.flow} />
    </PageWidth>
    <GetStartedSection bgColor="bg-primary" color="text-page" />
  </Layout>
}

function FlowSection({ flow }) {
  return flow ? <Section>
      <PageHeading>What to expect</PageHeading>
      <Flow width='12rem'>
          {flow.map((step) => <FlowStep step={step} />)}
      </Flow>
    </Section> : null
}

function FlowStep({step}) {
  return <div className='flex flex-col items-center text-center relative'>
    <div className='z-10 w-48 h-48 rounded-full bg-white overflow-hidden flex items-center justify-center'>
      <Image image={step.image} className='w-full' />
    </div>
    <RichText body={step.body} className='text-center bg-primary mt-2 mb-8 md:mx-5' />
  </div>
}

export const query = graphql`query($key: String!) {
  site: site {
    siteMetadata {
      url
    }
  }
  service: contentfulSiteService(key: {eq: $key}) {
    ...Service
    metaDescription
    flow {
      ...Content
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`